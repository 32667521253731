import styled from 'styled-components';

export const Alert = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: var(--gap);
    width: 100%;
    border-radius: var(--border-radius);
    background: var(--${props => props.status});
    color: var(--${props => props.color});

    & * {
        margin: 8px;
    }
`;

export const AlertGroup = styled.div`
    display: flex;
    flex-direction: column;
`;
