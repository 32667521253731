import React from 'react';
import { Helmet } from 'react-helmet';

import ContactForm from '../components/features/ContactForm';
import LocationCards from '../components/features/LocationCards';

import { Container } from '../components/layout/Container';

export default function Contact() {
    return (
        <>
            <Helmet>
                <title>The Egg & Us - Contact Us</title>
            </Helmet>
            <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/issaquah/IMG_9858_mowshf.jpg" alt="The Egg & Us Hero" />
            <Container>
                {/* <ContactForm /> */}
                <h2>Locations</h2>
                <p>Come visit us at any one of our locations!</p>
                <LocationCards />
            </Container>
        </>
    );
}