import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Topbar = styled.nav`
    position: fixed;
    z-index: 12;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: var(--gap) calc(var(--gap) * 2);
    background: var(--primary);
    box-shadow: -2px 2px 4px var(--gray-6);
`;

export const TopbarNav = styled(motion.div)`
    svg {
        width: 2.5rem;
        height: 2.5rem;
    }

    @media (max-width: 47.9rem) {
        display: ${props => props.nav ? 'flex' : 'none'};
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        background: var(--primary);
    }

    @media (min-width: 48rem) {
        display: flex;
        justify-content: space-evenly;
    }
`;

export const TopbarMobileNav = styled.div`
    z-index: 12;
    position: relative;
    top: 0;
    left: 90;
    transform: translate(-90%, 0%);

    svg {
        fill: inherit;
        width: calc(var(--font-size) * 1.5);
        height: calc(var(--font-size) * 1.5);
        cursor: pointer;
    }

    @media (min-width: 48rem) {
        display: none;
    }
`;

export const TopbarNavItem = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    svg {
        margin-right: calc(var(--gap) * 0.5);
    }

    @media (max-width: 47.9rem) {
        svg {
            display: none;
        }
    }
`;

export const TopbarLogo = styled.div`
    img, svg {
        width: ${props => props.width};
        height: ${props => props.height};
    }
`;