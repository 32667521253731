import React, { useState, useEffect } from 'react';

import { Grid } from '../layout/Grid';
import ImageModal from '../shared/ImageModal';

export default function Gallery(props) {
    const [gallery, setGallery] = useState(null);

    useEffect(() => {
        setGallery(props.photos.map(photo => {
            return (
                <ImageModal img={photo.src} alt={photo.alt} />
            );
        }));
    });

    return (
        <Grid gap={1} cols={2}>
            {gallery}
        </Grid>
    )
}