import styled from 'styled-components';

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: calc(var(--gap) * 2);
    background: var(--tertiary);
`;

export const FooterCopyright = styled.div`
    font-size: calc(var(--font-size) * 0.85);
    
    > * {
        margin-bottom: 0;
    }
`;

export const FooterNav = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;

export const FooterNavItem = styled.a`
    margin-right: var(--gap);
    color: inherit;
    border-bottom: 1px solid transparent;
    transition: all 0.25s linear;
    &:hover {
        border-bottom: 1px solid var(--dark);
    }
`;