import React from 'react';

import LocationCard from './LocationCard';
import { Grid } from '../layout/Grid';

export default function LocationCards() {
    const locationInfo = [
        {
            id: 1, 
            name: 'The Egg & Us - Issaquah', 
            srcset1: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_256/the-egg-and-us/issaquah-storefront.webp 256w',
            srcset2: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_512/the-egg-and-us/issaquah-storefront.webp 512w',
            src: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto/the-egg-and-us/issaquah-storefront.webp',
            address: '375 NW Gilman Blvd, Issaquah, WA 98027', 
            addressURL: 'http://www.google.com/maps/search/375%20NW%20Gilman%20Blvd%20Issaquah%2C%20Issaquah%2C%20WA%2098027',
            phone: '425-557-1919',
            url: 'https://theeggandus.com/locations/issaquah'
        },
        {
            id: 2,
            name: 'The Egg & Us - Ballard',
            srcset1: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_256/the-egg-and-us/ballard-storefront.webp 256w',
            srcset2: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_512/the-egg-and-us/ballard-storefront.webp 512w',
            src: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto/the-egg-and-us/ballard-storefront.webp',
            address: '4609 14th Ave NW Ste 108, Seattle, WA 98107',
            addressURL: 'http://www.google.com/maps/search/4609%2014th%20Ave%20NW%20Ste%20108%2C%20Seattle%2C%20WA%2098107',
            phone: '206-402-3080',
            url: 'https://theeggandus.com/locations/ballard'
        },
        {
            id: 3,
            name: 'Pete\'s Eggnest',
            srcset1: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_256/the-egg-and-us/petes-eggnest-storefront.webp 256w',
            srcset2: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_512/the-egg-and-us/petes-eggnest-storefront.webp 512w',
            src: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto/the-egg-and-us/petes-eggnest-storefront.webp',
            address: '7717 Greenwood Ave N, Seattle, WA 98103',
            addressURL: 'http://www.google.com/maps/search/7717%20Greenwood%20Ave%20N%2C%20Seattle%2C%20WA%2098103',
            phone: '206-784-5348',
            url: 'https://peteseggnest.com'
        },
        {
            id: 4,
            name: '12th Ave. Cafe',
            srcset1: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_256/the-egg-and-us/12th-ave-cafe-storefront.webp 256w',
            srcset2: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_512/the-egg-and-us/12th-ave-cafe-storefront.webp 512w',
            src: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto/the-egg-and-us/12th-ave-cafe-storefront.webp',
            address: '775 NW Gilman Blvd, Issaquah, WA 98027',
            addressURL: 'https://www.google.com/maps/search/775+NW+Gilman+Blvd,+Issaquah,+WA+98027',
            phone: '425-392-5975',
            url: 'https://www.12thavenuecafe.com'
        }
    ];

    const locations = locationInfo.map(location => {
        return (
            <LocationCard location={location} key={location.id} />
        );
    });

    return (
        <Grid cols={2} gap={1}>
            {locations}
        </Grid>
    );
}