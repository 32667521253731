import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import LocationCards from '../components/features/LocationCards';

import { Alert } from '../components/shared/Alert';
import { Container } from '../components/layout/Container';
import { Hero } from '../components/shared/Hero';

export default function Home() {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: i => ({
            opacity: 1,
            transition: {
                delay: i * 1
            }
        }),
        hidden: { opacity: 0 }
    }

    useEffect(() => {
        if (inView) controls.start("visible");
    }, [controls, inView]);

    return (
        <>
            <Hero bg="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/hero-img.jpg">
                <motion.h1
                    initial={{ opacity: "0", y: "500" }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ type: "spring", stiffness: 280, damping: 6, mass: 1, velocity: 40 }}
                >
                    Welcome to The Egg & Us!
                </motion.h1>
            </Hero>
            <Container
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={variants}
            >
                <Alert status="error" color="light">
                    <div>
                        <h2>Dear Egg & Us Customers,</h2>
                        <p>Due to an unforeseen electrical fire at our Issaquah location, we are currently unable to open our doors as we normally would. In the current moment, we are unsure when we will be operating as usual once again. However, it does not mean that we will not be back! We look forward to seeing and serving all our customers again in the near future. While we are working towards reopening, please come and have breakfast with us at one of our other restaurants!</p>
                    </div>
                </Alert>
                <h2>About Us</h2>
                <p>The Egg & Us is a friendly family-owned restaurant specializing in cozy and comforting breakfast food! We pride ourselves in not only providing great food, but a great dining experience as well. We have locations in Issaquah, Washington as well as the Ballard and Greenwood neighborhoods of Seattle so come in for one of the finest dining experiences around!</p>
                <h2>Locations</h2>
                <p>Come visit us at any one of our locations!</p>
                <LocationCards />
            </Container>
        </>
    );
}