import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Card = styled(motion.div)`
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    width: ${props => props.width ? props.width : '100%' };
    margin: var(--gap) 0;
    border-radius: var(--border-radius);
    background: var(--secondary);
    color: var(--dark);
    border: 1px solid var(--dark);
`;

export const CardTitle = styled.div`
    padding: 0 var(--gap);
    > * {
        margin-bottom: calc(var(--gap) * 0.5);
    }
`;

export const CardSubtitle = styled.div`
    > *{
        font-size: 1rem;
        font-weight: 400;
        color: var(--gray-3);
    }
`;

export const CardImg = styled.img`
    margin-bottom: var(--gap);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    width: 100%;
`;

export const CardBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: var(--gap);
`;