import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import ScrollToTop from './hooks/ScrollToTop';
import { GlobalStyles } from './components/GlobalStyles';

import Navbar from './components/features/Navbar';
import CustomFooter from './components/features/CustomFooter'; 

import Home from './pages/Home';
import Issaquah from './pages/Issaquah';
import Ballard from './pages/Ballard';
import Contact from './pages/Contact';

import { Wrapper } from './components/layout/Wrapper';

import { ReactComponent as Waves } from './assets/img/waves.svg';

const NoMatch = () => {
  let location = useLocation();

  return (
    <Redirect to="/" />
  );
}

export default function App() {
  return (
    <>
      <GlobalStyles />
      <ScrollToTop />
      <Navbar />
      <Wrapper>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/locations/issaquah" component={Issaquah} />
          <Route path="/locations/ballard" component={Ballard} />
          <Route path="/contact-us" component={Contact} />
        </Switch>
      </Wrapper>
      <Waves style={{ display: `block` }} />
      <CustomFooter />
    </>
  );
}