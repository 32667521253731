import styled from 'styled-components';

export const Grid = styled.div`
    display: grid;
    grid-gap: ${props => props.gap}rem;
    grid-template-columns: repeat(1, 1fr);
    
    @media (min-width: 48rem) {
        grid-template-columns: repeat(${props => props.cols}, 1fr);
    }
`;