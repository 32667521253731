import styled from 'styled-components';

import { Dropdown, DropdownLink } from './Dropdown';
import { Topbar } from './Topbar';

export const TextLink = styled.a`
    color: var(--dark);
    text-decoration: none;
    margin-bottom: var(--gap);
    &:hover {
        text-decoration: underline;
    }

    ${Topbar} & {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: 'Staatliches', cursive;
        font-size: calc(var(--font-size) * 2.5);
        color: inherit;
        text-decoration: none;
        padding: var(--gap) 0;
        margin: 0 var(--gap);
        &:hover {
            color: var(--light);
            text-decoration: underline;
        }

        @media (min-width: 48rem) {
            font-size: calc(var(--font-size) * 1.25);
        }
    }

    ${Dropdown} & {
        font-size: calc(var(--font-size) * 1.25);
        &:hover {
            color: var(--dark);
        }
    }

    ${DropdownLink} & {
        cursor: pointer;
    }
`; 