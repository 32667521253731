import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';

const StyledImageModal = styled.div`
    display: ${props => props.open ? 'block' : 'none'};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    
    svg {
        position: absolute;
        z-index: 12;
        top: 5%;
        left: 98%;
        transform: translate(-98%, -5%);
        width: 24px;
        height: 24px;
        fill: var(--light);
    }

    div:first-child {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        overflow: auto;
    }

    @media screen and (min-width: 768px) {
        div:first-child {
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;

const StyledImageModalBtn = styled.div`
    cursor: pointer;
`;

export default function ImageModal(props) {
    const [open, setOpen] = useState(false);

    const handleClick = () => setOpen(!open);

    return (
        <>
            <StyledImageModalBtn>
                <img src={props.img} alt={props.alt} onClick={handleClick} />
                <StyledImageModal open={open} onClick={handleClick}>
                    <div>
                        <img src={props.img} alt={props.alt} />
                    </div>
                    <CloseIcon onClick={handleClick} />
                </StyledImageModal>
            </StyledImageModalBtn>
        </>
    );
}

ImageModal.propTypes = {
    img: PropTypes.string,
    alt: PropTypes.string
}