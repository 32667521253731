import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Dropdown = styled(motion.div)`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: var(--secondary);
    padding: var(--gap) calc(var(--gap) * 2);
    border-radius: var(--border-radius);
    box-shadow: -2px 2px 4px var(--gray-6);
`;

export const DropdownLink = styled.div``;