import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled(motion.div)`
    width: 100%;
    padding: var(--gap);
    margin: 0 auto;

    @media (min-width: 0) {
        max-width: var(--max-width-xs);
    }

    @media (min-width: 36rem) {
        max-width: var(--max-width-sm);
    }

    @media (min-width: 48rem) {
        max-width: var(--max-width-md);
    }

    @media (min-width: 62rem) {
        max-width: var(--max-width-lg);
    }

    @media (min-width: 120rem) {
        max-width: var(--max-width-xl);
    }
`;