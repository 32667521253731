import styled from 'styled-components';

export const Form = styled.form`
    border-radius: var(--border-radius);
    font-family: 'Montserrat', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
    overflow: hidden;
    margin-bottom: var(--gap);
`;

export const FormHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.variant ? 'left' : 'center'};
    margin: var(--gap) 0;
`;

export const FormLabel = styled.label`
    font-size: 0.85rem;
    width: 100%;
    margin-left: 32px;
`;

export const FormInput = styled.input`
    border-radius: var(--border-radius);
    border: 1px solid var(--dark);
    width: 100%;
    padding: calc(var(--gap) * 1.25) var(--gap);
    outline: none;
    background: transparent;
    font-family: inherit;
    color: ${({ theme }) => theme.formText};
`;

export const FormTextarea = styled.textarea`
    border-radius: var(--border-radius);
    background: transparent;
    border: 1px solid var(--dark);
    width: 100%;
    padding: calc(var(--gap) * 1.25) var(--gap);
    outline: none;
    font-family: inherit;
`;

export const FormSelect = styled.select`
    border-radius: var(--border-radius);
    border: 1px solid var(--dark);
    padding: var(--gap);
    background: transparent;
    width: 100%;
    font-family: inherit;
`;