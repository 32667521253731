import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';

import { Footer, FooterNav, FooterNavItem, FooterCopyright } from '../shared/Footer';

import { ReactComponent as Waves } from '../../assets/img/waves.svg';

export default function CustomFooter() {
    return (
        <Footer>
            <FooterNav>
                <FooterNavItem href="https://www.facebook.com/eggandus" target="_blank" rel="noreferrer noopener">
                    <FontAwesomeIcon icon={faFacebookSquare} alt="Facebook Icon" />
                </FooterNavItem>
                <FooterNavItem href="https://www.instagram.com/theeggandus/?hl=en" target="_blank" rel="noreferrer noopener">
                    <FontAwesomeIcon icon={faInstagram} alt="Instagram Icon" />
                </FooterNavItem>
            </FooterNav>
            <FooterCopyright>
                <p>&copy; The Egg & Us 2021</p>
            </FooterCopyright>
        </Footer>
    );
}