import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Gallery from '../components/features/Gallery';

import { Alert, AlertGroup } from '../components/shared/Alert';
import Button from '../components/shared/Button';
import { Container } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import { TextLink } from '../components/shared/TextLink';

import { ReactComponent as CheckmarkIcon } from '../assets/icons/checkmark-icon.svg';

export default function Ballard() {
    const address = '4609 14th Ave NW Ste 108, Seattle, WA 98107';
    const addressURL = 'http://www.google.com/maps/search/4609%2014th%20Ave%20NW%20Ste%20108%2C%20Seattle%2C%20WA%2098107';
    const phone = '206-402-3080';
    const photos = [
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard/IMG_8212_r8pvwr.webp", alt: "Chicken Fried Steak"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard/IMG_8211_ecgewl.webp", alt: "Steak & Eggs"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard/IMG_8224_tgdssp.webp", alt: "Bacon & Eggs"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard/IMG_8229_g0bsdj.webp", alt: "Scramble"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard/IMG_8218_ay1acd.webp", alt: "Scramble"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard/IMG_8231_vbxlva.webp", alt: "Scramble"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard/IMG_8255_aafpzs.webp", alt: "Eggs Benedict"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard/IMG_8220_wn6h5k.webp", alt: "Salad"}
    ];
    
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: i => ({
            opacity: 1,
            transition: {
                delay: i * 1
            }
        }),
        hidden: { opacity: 0 }
    }

    useEffect(() => {
        if (inView) controls.start("visible");
    }, [controls, inView]);

    return (
        <>
            <Helmet>
                <title>The Egg & Us - Ballard</title>
                <meta property="og:title" content="The Egg & Us - Ballard" />
                <meta property="og:image" content="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_auto/the-egg-and-us/ballard-storefront.webp" />
                <meta property="og:url" content="https://theeggandus.com/locations/ballard" />
            </Helmet>
            <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/ballard-storefront.png" alt="The Egg & Us Ballard Storefront" />
            <Container
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={variants}
            >
                <h1>The Egg & Us Ballard</h1>
                <Grid cols={2} gap={0}>
                    <div>
                        <h2>Location</h2>
                        <TextLink href={addressURL} target="_blank" rel="noreferrer noopener">{address}</TextLink>
                    </div>
                    <div>
                        <h2>Phone</h2>
                        <TextLink href={`tel:${phone}`} data-bb-track-label="Location" data-bb-track="button" data-bb-track-on="click" data-bb-track-action="Click" data-bb-track-category="Phone Number">{phone}</TextLink>
                    </div>
                    <div>
                        <h2>Hours</h2>
                        <p>Monday - Saturday: 7:00 AM - 8:00 PM</p>
                        <p>Sunday: 7:00 AM - 3:00 PM</p>
                    </div>
                    <div>
                        <h2>Menu</h2>
                        <TextLink href="https://drive.google.com/file/d/1VsY67rEXiCGAdeNUUvxmuZska-hWNXxy/view?usp=sharing" target="_blank" rel="noreferrer noopener">The Egg & Us Ballard Menu</TextLink>
                        <TextLink href="https://drive.google.com/file/d/18S-Sm_mPBOWTwwshDsixDfdJzJk6pzV_/view?usp=sharing" target="_blank" rel="noreferrer noopener">The Egg & Us Ballard Mexican Menu & Drinks Menu</TextLink>
                    </div>
                </Grid>
                <h2>Offering</h2>
                <Grid cols={2} gap={1}>
                    <Alert status="success" color="dark">
                        <CheckmarkIcon /> Indoor Dining
                    </Alert>
                    <Alert status="success" color="dark">
                        <CheckmarkIcon /> Outdoor Dining
                    </Alert>
                    <Alert status="success" color="dark">
                        <CheckmarkIcon /> To-go
                    </Alert>
                    <Alert status="success" color="dark">
                        <CheckmarkIcon /> Delivery
                    </Alert>
                </Grid>
                <h2>Online Ordering</h2>
                <Grid gap={1} cols={2}>
                    <Button as="a" bg="toast" color="light" size="md" href="https://order.toasttab.com/online/the-egg-us-4609-14th-ave-nw" target="_blank" rel="noreferrer noopener">Order with Toast</Button>
                    <Button as="a" bg="ubereats" color="dark" size="md" href="https://www.ubereats.com/seattle/food-delivery/the-egg-&-us/j9eouq9_RL6sO50x4YRezQ/" target="_blank" rel="noreferrer noopener">Order With Uber Eats</Button>
                    <Button as="a" bg="doordash" color="light" size="md" href="https://www.doordash.com/store/the-egg-us-seattle-101004/en-US" target="_blank" rel="noreferrer noopener">Order With Doordash</Button>
                </Grid>
                <h2>Gallery</h2>
                <Gallery photos={photos} />
            </Container>
        </>
    );
}