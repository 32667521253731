import styled from 'styled-components';

export const Hero = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: url("${props => props.bg}") no-repeat center;
    background-size: cover;
    color: var(--primary);
    text-transform: uppercase;
    overflow: hidden;

    > * {
        padding: 0 calc(var(--gap) * 2);
        font-size: calc(var(--font-size) * 4);
    }

    h1 {
        text-shadow: -1px 1px var(--dark), -2px 2px var(--dark), -3px 3px var(--dark), -4px 4px var(--dark), -5px 5px var(--dark), -6px 6px var(--dark), -7px 7px var(--dark), -8px 8px var(--dark)
    }
`;