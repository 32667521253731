import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import Gallery from '../components/features/Gallery';

import { Alert, AlertGroup } from '../components/shared/Alert';
import Button from '../components/shared/Button';
import { Container } from '../components/layout/Container';
import { Grid } from '../components/layout/Grid';
import { TextLink } from '../components/shared/TextLink';

import { ReactComponent as CheckmarkIcon } from '../assets/icons/checkmark-icon.svg';
import { ReactComponent as OffIcon } from '../assets/icons/off-icon.svg';

export default function Issaquah() {
    const address = '375 NW Gilman Blvd, Issaquah, WA 98027';
    const addressURL = 'http://www.google.com/maps/search/375%20NW%20Gilman%20Blvd%20Issaquah%2C%20Issaquah%2C%20WA%2098027';
    const phone = '425-557-1919';
    const photos = [
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/v1618086748/the-egg-and-us/issaquah/IMG_9874_m9kzta.webp", alt: "Lemon Ricotta Pancakes"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/v1618086740/the-egg-and-us/issaquah/IMG_9788_wvedhx.webp", alt: "Crab Eggs Benedict"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/v1618086743/the-egg-and-us/issaquah/IMG_9824_z0yqv3.webp", alt: "Kitchen Sink Sandwich"},
        {src: "https://res.cloudinary.com/dwot7f2oz/image/upload/v1618086744/the-egg-and-us/issaquah/IMG_9830_wcpbcm.webp", alt: "Prime Rib Scramble"}
    ];
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: i => ({
            opacity: 1,
            transition: {
                delay: i * 1
            }
        }),
        hidden: { opacity: 0 }
    }

    useEffect(() => {
        if (inView) controls.start("visible");
    }, [controls, inView]);

    return (
        <>
            <Helmet>
                <title>The Egg & Us - Issaquah</title>
                <meta property="og:title" content="The Egg & Us - Issaquah" />
                <meta property="og:image" content="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/issaquah/IMG_9743_vebs8r.webp" />
                <meta property="og:url" content="https://theeggandus.com/locations/issaquah" />
            </Helmet>
            <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/the-egg-and-us/issaquah/IMG_9743_vebs8r.webp" alt="The Egg & Us - Issaquah Storefront" />
            <Container
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={variants}
            >
                <h1>The Egg & Us Issaquah</h1>
                <Alert status="error" color="light">
                    <div>
                        <h2>Dear Egg & Us Customers,</h2>
                        <p>Due to an unforeseen electrical fire at our Issaquah location, we are currently unable to open our doors as we normally would. In the current moment, we are unsure when we will be operating as usual once again. However, it does not mean that we will not be back! We look forward to seeing and serving all our customers again in the near future. While we are working towards reopening, please come and have breakfast with us at one of our other restaurants!</p>
                    </div>
                </Alert>
                <Grid cols={2} gap={0}>
                    <div>
                        <h2>Location</h2>
                        <TextLink href={addressURL} target="_blank" rel="noreferrer noopener">{address}</TextLink>
                    </div>
                    <div />
                    <div>
                        <h2>Phone</h2>
                        <TextLink href={`tel:${phone}`} data-bb-track-label="Location" data-bb-track="button" data-bb-track-on="click" data-bb-track-action="Click" data-bb-track-category="Phone Number">{phone}</TextLink>
                    </div>
                    <div>
                    <h2>Hours</h2>
                        {/* <p>Monday - Sunday: 7:00 AM - 3:00 PM</p> */}
                        <p>Temporarily Closed</p>
                    </div>
                    {/* <div>
                        <h2>Menu</h2>
                        <TextLink href="https://drive.google.com/file/d/1vsO3acnZcleKvPIsTdXAxDhNj_gKwTiq/view?usp=sharing" target="_blank" rel="noreferrer noopener">The Egg & Us Issaquah Menu</TextLink>
                    </div> */}
                </Grid>
                <h2>Offering</h2>
                <Grid cols={2} gap={1}>
                    <Alert status="error" color="dark">
                        <OffIcon /> Indoor Dining
                    </Alert>
                    <Alert status="error" color="dark">
                        <OffIcon /> Outdoor Dining
                    </Alert>
                    <Alert status="error" color="dark">
                        <OffIcon /> To-go
                    </Alert>
                    <Alert status="error" color="dark">
                        <OffIcon /> Delivery
                    </Alert>
                </Grid>
                {/* <h2>Online Ordering</h2>
                <Grid gap={1} cols={2}>
                    <Button as="a" bg="grubhub" color="light" size="md" href="https://www.grubhub.com/restaurant/the-egg--us-issaquah-375-nw-gilman-blvd-issaquah/2106728" target="_blank" rel="noreferrer noopener">Order With Grubhub</Button>
                    <Button as="a" bg="postmates" color="light" size="md" href="https://postmates.com/merchant/the-egg-us-issaquah-issaquah" target="_blank" rel="noreferrer noopener">Order With Postmates</Button>
                </Grid> */}
                <h2>Gallery</h2>
                <Gallery photos={photos} />
            </Container>
        </>
    );
}