import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';

import Button from '../shared/Button';
import { Card, CardTitle, CardBody, CardImg } from '../shared/Card';
import { TextLink } from '../shared/TextLink';

export default function LocationCard(props) {
    const controls = useAnimation();
    const [ref, inView] = useInView();
    const variants = {
        visible: i => ({
            opacity: 1,
            transition: {
                delay: i * 1
            }
        }),
        hidden: { opacity: 0 }
    }

    useEffect(() => {
        if (inView) controls.start("visible");
    }, [controls, inView]);

    return (
        <Card
            ref={ref}
            animate={controls}
            initial="hidden"
            variants={variants}
        >
            <CardImg
                sizes="(min-width: 30em) 28em, 100vw"
                srcSet={`
                    ${props.location.srcset1},
                    ${props.location.srcset2}
                `}
                src={props.location.src} 
                alt={`${props.location.name} Storefront`} 
            />
            <CardTitle>
                <h4>{props.location.name}</h4>
            </CardTitle>
            <CardBody>
                <TextLink href={props.location.addressURL} target="_blank" rel="noreferrer noopener">{props.location.address}</TextLink>
                <TextLink href={`tel:${props.location.phone}`} data-bb-track-label="Location" data-bb-track="button" data-bb-track-on="click" data-bb-track-action="Click" data-bb-track-category="Phone Number">{props.location.phone}</TextLink>
                <Button as="a" bg="primary" color="dark" size="md" href={props.location.url} target="_blank" rel="noreferrer noopener">Visit {props.location.name}</Button>
            </CardBody>
        </Card>
    );
}